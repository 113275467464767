import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import healthImg from '../assets/healthandwellness.jpg';
import health1 from '../assets/health1.jpg';
import health2 from '../assets/health2.jpg';
import health3 from '../assets/health3.jpg';

const activities = [
    {
        name: "Beachfront Meditation",
        description: "Find serenity with ocean waves"
    },
    {
        name: "Guided Meditation",
        description: "Journey inward with expert guidance"
    },
    {
        name: "Spa and Wellness Treatments",
        description: "Rejuvenate body and mind"
    },
    {
        name: "Performance Nutrition Seminar",
        description: "Fuel your body for peak performance"
    },
    {
        name: "Mindfulness for Leadership",
        description: "Enhance decision-making through presence"
    },
    {
        name: "CEO Burnout Prevention Workshops",
        description: "Sustain your passion and productivity"
    },
    {
        name: "Executive Strength Training",
        description: "Build resilience inside and out"
    },
    {
        name: "Sleep Optimization Workshops",
        description: "Maximize rest for peak performance"
    },
];

function HealthWellness() {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="min-h-screen bg-beige text-dark-brown overflow-hidden"
        >
            {/* Hero Section */}
            <div className="relative h-[75vh]">
                <img src={healthImg} alt="Health and Wellness" className="w-full h-full object-cover" />
                <div className="absolute inset-0 md:bg-gradient-to-r md:from-black md:via-transparent md:to-transparent flex items-center">
                    <div className="container mx-auto px-4">
                        <motion.div
                            initial={{ x: -100, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ type: "spring", stiffness: 100 }}
                            className="md:bg-transparent md:p-0 bg-black bg-opacity-50 p-6 rounded-lg inline-block"
                        >
                            <h1 className="text-4xl md:text-7xl font-serif text-gold leading-tight max-w-2xl">
                                Elevate Your <span className="text-beige">Wellbeing</span>
                            </h1>
                            <p className="text-xl text-beige max-w-xl mt-4 md:hidden">
                                Transform your mind, body, and leadership
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="container mx-auto px-4 py-16">
                {/* Intro Text */}
                <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className="max-w-2xl mx-auto text-center mb-16"
                >
                    <p className="text-xl md:text-2xl mb-6 leading-relaxed">
                        Immerse yourself in a transformative experience designed to rejuvenate your mind, body, and leadership prowess.
                    </p>
                    <p className="text-lg md:text-xl mb-6 leading-relaxed">
                        Our tailored wellness programs blend ancient wisdom with cutting-edge science, empowering you to lead with renewed vitality.
                    </p>
                </motion.div>

                {/* Image Section */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
                    <motion.div
                        className="col-span-1 md:col-span-2 md:row-span-2"
                        initial={{ x: -50, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.4 }}
                    >
                        <img src={health1} alt="Health Activity 1" className="w-full h-full object-cover rounded-lg shadow-2xl" />
                    </motion.div>
                    <motion.div
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.6 }}
                    >
                        <img src={health2} alt="Health Activity 2" className="w-full h-full object-cover rounded-lg shadow-2xl" />
                    </motion.div>
                    <motion.div
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.8 }}
                    >
                        <img src={health3} alt="Health Activity 3" className="w-full h-full object-cover rounded-lg shadow-2xl" />
                    </motion.div>
                </div>

                {/* Featured Activities */}
                <motion.h2
                    className="text-3xl md:text-4xl font-serif text-gold mb-12 text-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                >
                    Curated Experiences
                </motion.h2>
                <motion.div
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1.2, staggerChildren: 0.1 }}
                >
                    {activities.map((activity, index) => (
                        <motion.div
                            key={index}
                            className="bg-white p-6 rounded-lg shadow-lg text-center transform hover:-translate-y-2 transition-all duration-300"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <h3 className="text-lg md:text-xl text-gold mb-2">{activity.name}</h3>
                            <p className="text-dark-brown text-opacity-80 text-sm md:text-base">{activity.description}</p>
                        </motion.div>
                    ))}
                </motion.div>

                {/* Medical Support Section */}
                <motion.div
                    className="bg-dark-brown text-beige p-8 md:p-12 rounded-lg shadow-2xl mb-16 relative overflow-hidden"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1.4 }}
                >
                    <div className="absolute top-0 right-0 w-1/3 h-full bg-gold opacity-10 transform -skew-x-12"></div>
                    <h3 className="text-2xl md:text-3xl font-serif text-gold mb-6 relative z-10">Professional Medical Support</h3>
                    <p className="text-lg md:text-xl leading-relaxed max-w-3xl relative z-10">
                        Your well-being is our priority. Throughout your retreat, a dedicated medical professional is at your service, ensuring personalized care and peace of mind. Focus on your transformation journey, knowing that your health is in expert hands.
                    </p>
                </motion.div>

                {/* CTA */}
                <motion.div
                    className="text-center"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1.6 }}
                >
                    <Link
                        to="/contact"
                        className="bg-gold text-dark-brown px-8 md:px-12 py-3 md:py-4 rounded-full text-lg md:text-xl font-sans hover:bg-dark-brown hover:text-gold transition duration-300 inline-block shadow-lg"
                    >
                        Begin Your Wellness Journey
                    </Link>
                </motion.div>
            </div>
        </motion.div>
    );
}

export default HealthWellness;
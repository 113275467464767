import React from 'react';
import HeroSection from '../components/HeroSection';
import RetreatSection from '../components/RetreatSection';

import healthAndWellnessImg from '../assets/healthandwellness.jpg';
import teamBuildingImg from '../assets/teambuilding.jpg';
import networkingImg from '../assets/networking.jpeg';
import leisureImg from '../assets/leisure.jpg';

function Home() {
    return (
        <div>
            <HeroSection />
            <RetreatSection
                title="HEALTH AND WELLNESS"
                description="Elevate your leadership by focusing on your well-being. Our island retreats offer tailored wellness programs—guided meditation, personalized fitness, and stress-relief activities."
                imageUrl={healthAndWellnessImg}
                activities={["Beachfront Yoga", "Guided Meditation", "Spa and Wellness Treatments"]}
                path="/health-wellness"
            />
            <RetreatSection
                title="TEAM BUILDING"
                description="Strengthen your team in paradise. Our retreats blend stunning island settings with strategic team-building exercises that foster collaboration, trust, and problem-solving."
                imageUrl={teamBuildingImg}
                activities={["Island Scavenger Hunt", "Sailing Regatta", "Strategic Vision Workshops"]}
                reverse={true}
                path="/team-building"
            />
            <RetreatSection
                title="NETWORKING"
                description="Expand your network in an exclusive, relaxed setting. Connect with high-level executives from various industries while enjoying the luxury of island life."
                imageUrl={networkingImg}
                activities={["Executive Cocktail Parties", "Island Leadership Panels", "Luxury Yacht Cruises"]}
                path="/networking"
            />
            <RetreatSection
                title="LEISURE"
                description="Leadership thrives on balance. Indulge in world-class leisure experiences—private beach outings, island adventures, and luxury relaxation."
                imageUrl={leisureImg}
                activities={["Luxury Catamaran Cruises", "Private Beach Bonfire Parties", "Cultural Island Excursions"]}
                reverse={true}
                path="/leisure"
            />
        </div>
    );
}

export default Home;
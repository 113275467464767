import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

import beachImage from '../assets/beach.jpg';
import businessImage from '../assets/business.jpg';
import retreatImage from '../assets/retreat.jpg';
import waterImage from '../assets/water.jpg';

const images = [beachImage, businessImage, retreatImage, waterImage];

function HeroSection() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000);

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            clearInterval(timer);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const scrollToContent = () => {
        window.scrollTo({
            top: window.innerHeight * 0.6,
            behavior: 'smooth'
        });
    };

    return (
        <section className="relative h-[60vh] md:h-[80vh] overflow-hidden">
            <AnimatePresence initial={false}>
                <motion.div
                    key={currentImage}
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage: `url(${images[currentImage]})`,
                        backgroundPosition: isMobile ? 'center center' : 'center 30%'
                    }}
                    initial={{ opacity: 0, scale: 1.1 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    transition={{ duration: 1.5 }}
                />
            </AnimatePresence>
            <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-transparent opacity-50"></div>
            <div className="absolute inset-0 flex items-center justify-center text-center px-4">
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <h1 className="text-4xl md:text-7xl font-serif text-beige mb-4 drop-shadow-lg font-extrabold tracking-wide">
                        {isMobile ? (
                            <>
                                APEX EXECUTIVE<br />RETREATS
                            </>
                        ) : (
                            'APEX EXECUTIVE RETREATS'
                        )}
                    </h1>
                    <p className="text-lg md:text-2xl font-sans text-beige mb-8 drop-shadow-md font-bold uppercase tracking-wider">
                        Elevate Your Leadership<br className="md:hidden" /> in Paradise
                    </p>
                    <Link to="/contact">
                        <motion.button
                            className="bg-gold text-black px-6 md:px-8 py-3 text-base md:text-lg font-sans hover:bg-beige transition duration-300 rounded shadow-lg"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            INQUIRE NOW
                        </motion.button>
                    </Link>
                </motion.div>
            </div>
            <motion.div
                className="absolute bottom-8 left-1/2 transform -translate-x-1/2 cursor-pointer"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 1.5, repeat: Infinity, repeatType: "reverse" }}
                onClick={scrollToContent}
            >
                <svg className="w-8 h-8 md:w-10 md:h-10 text-beige" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                </svg>
                <span className="text-beige text-xs md:text-sm font-sans">Explore Our Retreats</span>
            </motion.div>
        </section>
    );
}

export default HeroSection;
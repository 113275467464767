import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

function AboutPage() {
    return (
        <motion.div
            className="container mx-auto px-4 py-20 mt-20"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="p-8 md:p-12">
                    <h1 className="text-5xl font-serif mb-8 text-dark-brown border-b-2 border-gold pb-4 text-center">ABOUT US</h1>
                    <h2 className="text-3xl font-serif mb-6 text-dark-brown text-center">Welcome to Apex Executive Retreats</h2>
                    <p className="mb-8 text-light-brown leading-relaxed">
                        Drawing from our extensive experience in training executives and companies through our parent company the Apex of Training and Development, we have decided to create the Apex Executive Retreats as a revolutionary step in helping companies achieve operational excellence across all sectors. Recognizing the intense pressures faced by today's executives, we have created an unparalleled experience that combines our expertise in high-quality training with the restorative power of carefully selected island retreats. This innovative approach allows executives to truly disconnect from the daily grind and immerse themselves in tranquil, exclusive environments.
                        By providing this unique blend of professional development and rejuvenation, we enable leaders to gain fresh perspectives, recharge their creativity, and cultivate mindfulness—all essential components for sustained success in the modern business landscape. The Apex Executive Retreats embodies our mission to go beyond conventional training methods, offering a holistic experience that nurtures both personal and organizational growth in an idyllic setting.
                    </p>
                    <p className="mb-8 text-light-brown leading-relaxed">
                        At Apex Executive Retreats, we offer exclusive, customizable retreats designed to elevate executive performance and foster organizational success. Our bespoke experiences cater to executives seeking to enhance their leadership skills and strategic vision.
                    </p>

                    <div className="bg-beige p-6 rounded-lg mb-8 shadow-md">
                        <h3 className="text-2xl font-serif mb-4 text-dark-brown">Choose Your Retreat Experience And Caribbean Destination:</h3>
                        <ol className="list-decimal list-inside space-y-6 text-light-brown">
                            {[
                                {
                                    title: "Leisure Retreats",
                                    items: [
                                        "Luxurious accommodations in serene island settings",
                                        "Curated recreational activities for relaxation and rejuvenation",
                                        "Opportunities for informal networking in a relaxed environment"
                                    ]
                                },
                                {
                                    title: "Health and Wellness Retreats",
                                    items: [
                                        "Comprehensive wellness programs led by expert practitioners",
                                        "Stress management and mindfulness workshops",
                                        "Nutritional guidance and fitness activities tailored to busy executives"
                                    ]
                                },
                                {
                                    title: "Networking Retreats",
                                    items: [
                                        "Structured networking events with industry leaders",
                                        "Collaborative problem-solving sessions",
                                        "Keynote speeches and panel discussions on current business trends"
                                    ]
                                },
                                {
                                    title: "Team Building Retreats",
                                    items: [
                                        "Custom-designed team challenges and activities",
                                        "Leadership workshops focused on communication and collaboration",
                                        "Strategic planning sessions in inspiring environments"
                                    ]
                                }
                            ].map((retreat, index) => (
                                <li key={index}>
                                    <span className="font-bold text-gold">{retreat.title}</span>
                                    <ul className="list-disc list-inside ml-6 mt-2 space-y-1">
                                        {retreat.items.map((item, i) => (
                                            <li key={i}>{item}</li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ol>
                    </div>

                    <h3 className="text-2xl font-serif mb-4 text-dark-brown">Key Features of All Retreats:</h3>
                    <ul className="list-disc list-inside mb-8 text-light-brown space-y-2">
                        <li>VIP airport escort and premium transportation services</li>
                        <li>Exclusive access to pristine natural environments</li>
                        <li>Flexible itineraries adaptable to your company's specific needs</li>
                        <li>Options for including spouses or partners in the retreat experience</li>
                    </ul>

                    <h3 className="text-2xl font-serif mb-4 text-dark-brown">Why Choose Apex Executive Retreats:</h3>
                    <div className="space-y-6 text-light-brown leading-relaxed">
                        <p>
                            At Apex Executive Retreats, we understand that true leadership requires moments of reflection, rejuvenation, and strategic thinking, which is why we craft experiences that promote growth and encourage meaningful connections.
                        </p>
                        <p>
                            Immerse yourself in the tranquility of our handpicked, exclusive island properties. From the moment you step off the plane, our VIP escort service ensures a seamless transition into a world of luxury and serenity. Here, amidst pristine natural beauty, you will find the perfect setting to repurpose your mind and gain the strategic foresight necessary to drive your company's success.
                        </p>
                        <p>
                            We also offer special packages for executives accompanied by their spouses. These retreats provide a unique opportunity for partners to gain a deeper appreciation for each other's roles, fostering a harmony that promotes success both in the workplace and at home.
                        </p>
                        <p>
                            At Apex Executive Retreats, we believe that a change in environment can spark transformative thinking. By stepping away from your busy work setting and into our serene landscapes, you will find yourself generating more innovative ideas and making clearer, more impactful decisions for your organization's future.
                        </p>
                        <p>
                            Invest in yourself, your team, and your company's success. Choose Apex Executive Retreats for an unparalleled experience that will redefine your approach to leadership and life.
                        </p>
                    </div>

                    <blockquote className="text-xl font-serif text-gold italic my-8 text-center border-l-4 border-gold pl-4">
                        Discover the power of strategic relaxation. Elevate your vision. Transform your success.
                    </blockquote>

                    <h3 className="text-2xl font-serif mb-4 text-dark-brown">COMMUNITY IMPACT & PARTNERSHIPS</h3>
                    <div className="space-y-4 text-light-brown leading-relaxed">
                        <p>
                            Apex Executive Retreats is deeply committed to supporting local Caribbean communities. Through our partnership with Community Charitable Ministry (CCM), a respected Friendly Society founded in 2008, we extend our impact beyond retreats to create lasting positive change.
                        </p>
                        <p>Our Community Engagement Includes:</p>
                        <ul className="list-disc list-inside ml-6 space-y-2">
                            <li>Supporting CCM's monthly distribution of essential supplies to families in need</li>
                            <li>Facilitating retreat participants' involvement in local charitable activities</li>
                            <li>Contributing to educational initiatives through school supply drives</li>
                            <li>Participating in health and wellness outreach programs</li>
                        </ul>
                        <p>
                            When you choose Apex Executive Retreats for your retreat, you are not just investing in personal or professional growth—you are contributing to the wellbeing of Caribbean communities. Our retreats often incorporate opportunities for participants to engage in meaningful community service, aligning corporate missions with real-world impact.
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-16 text-center">
                <Link
                    to="/contact"
                    className="inline-block bg-gold text-dark-brown px-8 py-3 rounded-full text-lg font-semibold hover:bg-dark-brown hover:text-gold transition duration-300 shadow-md"
                >
                    Get in Touch with Us
                </Link>
            </div>
        </motion.div>
    );
}

export default AboutPage;
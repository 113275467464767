import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

const RetreatSection = forwardRef(({ title, description, imageUrl, activities, reverse = false, path }, ref) => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const variants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <motion.section
            ref={(node) => {
                inViewRef(node);
                if (ref) {
                    ref.current = node;
                }
            }}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={variants}
            transition={{ duration: 0.5, ease: "easeOut" }}
            className={`py-20 ${reverse ? 'bg-light-brown bg-opacity-10' : 'bg-beige'}`}
        >
            <div className={`container mx-auto flex flex-col ${reverse ? 'md:flex-row-reverse' : 'md:flex-row'} items-center`}>
                <motion.div
                    className="md:w-1/2 px-4"
                    whileHover={{ scale: 1.03 }}
                    transition={{ duration: 0.3 }}
                >
                    <img src={imageUrl} alt={title} className="w-full h-auto shadow-lg rounded-lg" />
                </motion.div>
                <div className="md:w-1/2 px-4 mt-8 md:mt-0">
                    <h2 className="text-4xl font-serif mb-4 text-dark-brown">{title}</h2>
                    <p className="text-light-brown mb-6 font-sans">{description}</p>
                    <h3 className="text-2xl font-serif mb-2 text-dark-brown">Key Activities:</h3>
                    <ul className="list-disc list-inside mb-6">
                        {activities.map((activity, index) => (
                            <motion.li
                                key={index}
                                className="text-light-brown font-sans"
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                            >
                                {activity}
                            </motion.li>
                        ))}
                    </ul>
                    <Link to={path}>
                        <motion.button
                            className="bg-gold text-beige py-2 px-6 font-sans hover:bg-dark-brown transition duration-300 rounded"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            Learn More
                        </motion.button>
                    </Link>
                </div>
            </div>
        </motion.section>
    );
});

export default RetreatSection;
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import leisureImg from '../assets/leisure.jpg';
import leisure1 from '../assets/leisure1.jpg';
import leisure2 from '../assets/leisure2.jpg';
import leisure3 from '../assets/leisure3.jpg';

const activities = [
    {
        name: "Luxury Catamaran Cruises",
        description: "Sail in style through crystal waters"
    },
    {
        name: "Private Beach Bonfire Parties",
        description: "Unwind under the stars with peers"
    },
    {
        name: "Cultural Island Excursions",
        description: "Immerse in local traditions and history"
    },
    {
        name: "CEO Reflection Retreat",
        description: "Find clarity in serene environments"
    },
    {
        name: "Secluded Beach Bonfire Planning",
        description: "Strategize in intimate, relaxed settings"
    },
    {
        name: "Sunset Cruises",
        description: "Network while enjoying breathtaking views"
    },
    {
        name: "Volcanic Mud Bathing",
        description: "Rejuvenate with nature's spa treatments"
    },
    {
        name: "Executive Creativity Breaks",
        description: "Spark innovation through leisure"
    },
];

const Leisure = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="min-h-screen bg-beige text-dark-brown overflow-hidden"
        >
            {/* Hero Section */}
            <div className="relative h-[75vh]">
                <img src={leisure2} alt="Leisure" className="w-full h-full object-cover" />
                <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-transparent flex items-center">
                    <div className="container mx-auto px-4">
                        <motion.div
                            initial={{ x: -100, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ type: "spring", stiffness: 100 }}
                            className="md:bg-transparent md:p-0 bg-black bg-opacity-50 p-6 rounded-lg inline-block"
                        >
                            <h1 className="text-4xl md:text-7xl font-serif text-gold leading-tight max-w-2xl md:mb-0">
                                Indulge in <span className="text-beige">Luxury</span>
                            </h1>
                            <p className="text-xl text-beige max-w-xl mt-4 md:hidden">
                                Experience world-class leisure in breathtaking settings
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="container mx-auto px-4 py-16">
                {/* Intro Text */}
                <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className="max-w-2xl mx-auto text-center mb-16"
                >
                    <p className="text-xl md:text-2xl mb-6 leading-relaxed">
                        Leadership thrives on balance. Indulge in world-class leisure experiences—private beach outings, island adventures, and luxury relaxation.
                    </p>
                    <p className="text-lg md:text-xl mb-6 leading-relaxed">
                        Return recharged and inspired, ready to lead with fresh ideas and renewed clarity. Our retreats offer the perfect blend of relaxation and strategic reflection.
                    </p>
                </motion.div>

                {/* Image Grid */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
                    <motion.div
                        className="col-span-1 md:col-span-2 md:row-span-2"
                        initial={{ x: -50, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.4 }}
                    >
                        <img src={leisure1} alt="Leisure Activity 1" className="w-full h-full object-cover rounded-lg shadow-2xl" />
                    </motion.div>
                    <motion.div
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.6 }}
                    >
                        <img src={leisureImg} alt="Leisure Activity 2" className="w-full h-full object-cover rounded-lg shadow-2xl" />
                    </motion.div>
                    <motion.div
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.8 }}
                    >
                        <img src={leisure3} alt="Leisure Activity 3" className="w-full h-full object-cover rounded-lg shadow-2xl" />
                    </motion.div>
                </div>

                {/* Featured Activities */}
                <motion.h2
                    className="text-3xl md:text-4xl font-serif text-gold mb-12 text-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                >
                    Leisure Experiences
                </motion.h2>
                <motion.div
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1.2, staggerChildren: 0.1 }}
                >
                    {activities.map((activity, index) => (
                        <motion.div
                            key={index}
                            className="bg-white p-6 rounded-lg shadow-lg text-center transform hover:-translate-y-2 transition-all duration-300"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <h3 className="text-lg md:text-xl text-gold mb-2">{activity.name}</h3>
                            <p className="text-dark-brown text-opacity-80">{activity.description}</p>
                        </motion.div>
                    ))}
                </motion.div>

                {/* Leisure Impact Section */}
                <motion.div
                    className="bg-dark-brown text-beige p-8 md:p-12 rounded-lg shadow-2xl mb-16 relative overflow-hidden"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1.4 }}
                >
                    <div className="absolute top-0 right-0 w-1/3 h-full bg-gold opacity-10 transform -skew-x-12"></div>
                    <h3 className="text-2xl md:text-3xl font-serif text-gold mb-6 relative z-10">The Power of Relaxation</h3>
                    <p className="text-lg md:text-xl leading-relaxed max-w-3xl relative z-10">
                        In the fast-paced world of executive leadership, moments of true relaxation are rare and invaluable. Our curated leisure experiences provide more than just a break from the routine; they offer a chance to reset, reflect, and return to your responsibilities with renewed energy and perspective. Invest in your well-being and watch as it translates into enhanced leadership and decision-making abilities.
                    </p>
                </motion.div>

                {/* CTA */}
                <motion.div
                    className="text-center"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1.6 }}
                >
                    <Link
                        to="/contact"
                        className="bg-gold text-dark-brown px-8 md:px-12 py-3 md:py-4 rounded-full text-lg md:text-xl font-sans hover:bg-dark-brown hover:text-gold transition duration-300 inline-block shadow-lg"
                    >
                        Begin Your Luxury Retreat
                    </Link>
                </motion.div>
            </div>
        </motion.div>
    );
};

export default Leisure;
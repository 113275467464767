import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';

function Header() {
    const [scrollProgress, setScrollProgress] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleScroll = () => {
            const totalScroll = document.documentElement.scrollHeight - window.innerHeight;
            const currentScroll = window.pageYOffset;
            const progress = Math.min(currentScroll / (totalScroll * 0.2), 1);
            setScrollProgress(progress);
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const navItems = [
        { name: 'ABOUT', path: '/about' },
        { name: 'HEALTH & WELLNESS', path: '/health-wellness' },
        { name: 'TEAM BUILDING', path: '/team-building' },
        { name: 'NETWORKING', path: '/networking' },
        { name: 'LEISURE', path: '/leisure' },
        { name: 'DESTINATIONS', path: '/destinations' }
    ];

    const isHomePage = location.pathname === '/';
    const isAboutPage = location.pathname === '/about';
    const isSpecialPage = isHomePage || isAboutPage;

    const headerOpacity = isMobile && isSpecialPage ? scrollProgress : 1;
    const contentOpacity = isMobile && isSpecialPage ? scrollProgress : 1;
    const headerBackground = isMobile && isSpecialPage
        ? (scrollProgress > 0 ? 'bg-black' : 'bg-transparent')
        : 'bg-black bg-opacity-90';

    return (
        <header
            className={`fixed w-full z-50 transition-all duration-300 ${headerBackground}`}
            style={{ opacity: headerOpacity }}
        >
            <div className="container mx-auto px-6 py-3 md:py-3.5 flex justify-between items-center">
                <motion.div
                    className="flex items-center"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: contentOpacity, x: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <h1 className="text-2xl md:text-2xl font-serif text-gold">
                        <Link to="/">APEX EXECUTIVE RETREATS</Link>
                    </h1>
                </motion.div>
                <div className="flex items-center">
                    <nav className="hidden md:block">
                        <ul className="flex space-x-5">
                            {navItems.map((item, index) => (
                                <motion.li
                                    key={item.name}
                                    initial={{ opacity: 0, y: -20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                >
                                    <Link
                                        to={item.path}
                                        className={`font-sans text-sm text-gold hover:text-beige transition duration-300 ${location.pathname === item.path ? 'border-b-2 border-gold' : ''}`}
                                    >
                                        {item.name}
                                    </Link>
                                </motion.li>
                            ))}
                        </ul>
                    </nav>
                    <motion.button
                        className="hidden md:block bg-gold text-black px-4 py-2.5 text-sm font-sans hover:bg-beige transition duration-300 rounded ml-5"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <Link to="/contact">INQUIRE NOW</Link>
                    </motion.button>
                    <button
                        className="md:hidden text-gold text-lg"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        style={{ opacity: contentOpacity }}
                    >
                        {isMenuOpen ? 'Close' : 'Menu'}
                    </button>
                </div>
            </div>
            {isMenuOpen && (
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="md:hidden bg-black bg-opacity-90 p-6"
                >
                    <ul className="space-y-4">
                        {navItems.map((item) => (
                            <li key={item.name}>
                                <Link
                                    to={item.path}
                                    className={`font-sans text-base text-gold hover:text-beige transition duration-300 block ${location.pathname === item.path ? 'border-b-2 border-gold' : ''}`}
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <motion.button
                        className="bg-gold text-black px-6 py-3 text-base font-sans hover:bg-beige transition duration-300 rounded mt-6 w-full"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <Link to="/contact" onClick={() => setIsMenuOpen(false)}>INQUIRE NOW</Link>
                    </motion.button>
                </motion.div>
            )}
        </header>
    );
}

export default Header;
import React, { useState } from 'react';
import { motion } from 'framer-motion';

function ContactForm() {
    const [status, setStatus] = useState('');

    const submitForm = async (e) => {
        e.preventDefault();
        const form = e.target;
        const data = new FormData(form);
        const response = await fetch('https://formspree.io/f/xanwrdkq', {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json'
            }
        });
        if (response.ok) {
            setStatus("Thank you for your message. We'll be in touch soon!");
            form.reset();
        } else {
            setStatus("Oops! There was a problem submitting your form. Please try again.");
        }
    };

    return (
        <motion.div
            className="container mx-auto px-4 py-20 mt-20 bg-white shadow-xl rounded-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <h1 className="text-4xl font-serif mb-8 text-dark-brown text-center">Contact Us</h1>
            <p className="text-light-brown text-center mb-8">We are excited to hear from you and discuss how we can elevate your executive experience.</p>
            <form onSubmit={submitForm} className="max-w-3xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="mb-4">
                    <label htmlFor="firstName" className="block text-light-brown mb-2">First Name*</label>
                    <input type="text" id="firstName" name="firstName" required className="w-full px-4 py-2 border border-light-brown rounded-md focus:outline-none focus:ring-2 focus:ring-gold" />
                </div>
                <div className="mb-4">
                    <label htmlFor="lastName" className="block text-light-brown mb-2">Last Name*</label>
                    <input type="text" id="lastName" name="lastName" required className="w-full px-4 py-2 border border-light-brown rounded-md focus:outline-none focus:ring-2 focus:ring-gold" />
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-light-brown mb-2">Email Address*</label>
                    <input type="email" id="email" name="email" required className="w-full px-4 py-2 border border-light-brown rounded-md focus:outline-none focus:ring-2 focus:ring-gold" />
                </div>
                <div className="mb-4">
                    <label htmlFor="phone" className="block text-light-brown mb-2">Phone Number</label>
                    <input type="tel" id="phone" name="phone" className="w-full px-4 py-2 border border-light-brown rounded-md focus:outline-none focus:ring-2 focus:ring-gold" />
                </div>
                <div className="mb-4 md:col-span-2">
                    <label htmlFor="company" className="block text-light-brown mb-2">Company</label>
                    <input type="text" id="company" name="company" className="w-full px-4 py-2 border border-light-brown rounded-md focus:outline-none focus:ring-2 focus:ring-gold" />
                </div>
                <div className="mb-4 md:col-span-2">
                    <label htmlFor="message" className="block text-light-brown mb-2">Additional Information/Comments</label>
                    <textarea id="message" name="message" rows="4" className="w-full px-4 py-2 border border-light-brown rounded-md focus:outline-none focus:ring-2 focus:ring-gold"></textarea>
                </div>
                <div className="md:col-span-2 text-center">
                    <motion.button
                        type="submit"
                        className="bg-gold text-dark-brown px-8 py-3 rounded-full text-lg font-semibold hover:bg-dark-brown hover:text-gold transition duration-300 shadow-md"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        Send Message
                    </motion.button>
                </div>
            </form>
            {status && (
                <motion.p
                    className="mt-6 text-center text-light-brown bg-beige p-4 rounded-md"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    {status}
                </motion.p>
            )}
            <p className="text-sm text-light-brown text-center mt-8">
                Your privacy is important to us. We'll never share your information without your permission.
            </p>
        </motion.div>
    );
}

export default ContactForm;
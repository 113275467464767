import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // Make sure this path is correct

function Footer() {
    const navItems = [
        { name: 'About', path: '/about' },
        { name: 'Health & Wellness', path: '/health-wellness' },
        { name: 'Team Building', path: '/team-building' },
        { name: 'Networking', path: '/networking' },
        { name: 'Leisure', path: '/leisure' },
        { name: 'Destinations', path: '/destinations' }
    ];

    return (
        <footer className="bg-dark-brown text-beige py-8">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-between items-center">
                    <motion.div
                        className="w-full md:w-auto mb-4 md:mb-0 flex items-center"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <img src={logo} alt="Apex Executive Retreats" className="h-20 md:h-24 mr-4" />
                        <h3 className="text-xl font-serif text-gold">APEX EXECUTIVE RETREATS</h3>
                    </motion.div>
                    <motion.div
                        className="w-full md:w-auto mb-4 md:mb-0"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        <ul className="flex flex-wrap justify-center md:justify-end space-x-4">
                            {navItems.map((item) => (
                                <motion.li
                                    key={item.name}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <Link to={item.path} className="text-sm hover:text-gold transition duration-300 font-sans">{item.name}</Link>
                                </motion.li>
                            ))}
                        </ul>
                    </motion.div>
                </div>
                <motion.div
                    className="border-t border-gold border-opacity-20 mt-4 pt-4 text-center text-sm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                >
                    <p className="font-sans text-beige text-opacity-70">
                        &copy; 2024 Apex Executive Retreats. All rights reserved.
                        <span className="mx-2">|</span>
                        <a href="mailto:apex@ela320.com" className="hover:text-gold transition duration-300">info@apexcorporateretreat.com</a>
                        <span className="mx-2">|</span>
                        <a href="tel:(334)246-2635" className="hover:text-gold transition duration-300">(334)246-2635</a>
                        <span className="mx-2">|</span>
                        <span>(268)720-5523</span>
                        <span className="mx-2">|</span>
                        <span>(264)583-8416</span>
                    </p>
                </motion.div>
            </div>
        </footer>
    );
}

export default Footer;
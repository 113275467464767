import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import teamBuildingImg from '../assets/teambuilding.jpg';
import team1 from '../assets/team1.jpg';
import team2 from '../assets/team2.jpg';
import team3 from '../assets/team3.jpg';

const activities = [
    {
        name: "Island Scavenger Hunt",
        description: "Explore and bond through strategic challenges"
    },
    {
        name: "Sailing Regatta",
        description: "Navigate teamwork on the open seas"
    },
    {
        name: "Strategic Vision Workshops",
        description: "Align goals and craft shared futures"
    },
    {
        name: "Leadership Role-Playing Scenarios",
        description: "Practice adaptive leadership skills"
    },
    {
        name: "Mission Alignment Hike",
        description: "Trek towards unified company objectives"
    },
    {
        name: "Innovation Sprint",
        description: "Accelerate creativity and problem-solving"
    },
    {
        name: "Strengths-Based Leadership Exercises",
        description: "Leverage individual talents for team success"
    },
    {
        name: "Company Culture Building",
        description: "Shape and reinforce your unique ethos"
    },
];

const TeamBuilding = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="min-h-screen bg-beige text-dark-brown overflow-hidden"
        >
            {/* Hero Section */}
            <div className="relative h-[75vh]">
                <img src={teamBuildingImg} alt="Team Building" className="w-full h-full object-cover" />
                <div className="absolute inset-0 md:bg-gradient-to-r md:from-black md:via-transparent md:to-transparent flex items-center">
                    <div className="container mx-auto px-4">
                        <motion.div
                            initial={{ x: -100, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ type: "spring", stiffness: 100 }}
                            className="md:bg-transparent md:p-0 bg-black bg-opacity-50 p-6 rounded-lg inline-block"
                        >
                            <h1 className="text-4xl md:text-7xl font-serif text-gold leading-tight max-w-2xl">
                                Forge <span className="text-beige">Unbreakable Bonds</span>
                            </h1>
                            <p className="text-xl text-beige max-w-xl mt-4 md:hidden">
                                Strengthen your team in paradise
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="container mx-auto px-4 py-16">
                {/* Intro Text */}
                <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className="max-w-2xl mx-auto text-center mb-16"
                >
                    <p className="text-xl md:text-2xl mb-6 leading-relaxed">
                        Strengthen your team in paradise. Our retreats blend stunning island settings with strategic team-building exercises that foster collaboration, trust, and problem-solving.
                    </p>
                    <p className="text-lg md:text-xl mb-6 leading-relaxed">
                        Your team will leave more connected, aligned, and ready to tackle challenges with newfound synergy and momentum. Return as a unified force ready to drive success within your organization.
                    </p>
                </motion.div>

                {/* Image Grid */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
                    <motion.div
                        className="col-span-1 md:col-span-2 md:row-span-2"
                        initial={{ x: -50, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.4 }}
                    >
                        <img src={team1} alt="Team Activity 1" className="w-full h-full object-cover rounded-lg shadow-2xl" />
                    </motion.div>
                    <motion.div
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.6 }}
                    >
                        <img src={team2} alt="Team Activity 2" className="w-full h-full object-cover rounded-lg shadow-2xl" />
                    </motion.div>
                    <motion.div
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.8 }}
                    >
                        <img src={team3} alt="Team Activity 3" className="w-full h-full object-cover rounded-lg shadow-2xl" />
                    </motion.div>
                </div>

                {/* Featured Activities */}
                <motion.h2
                    className="text-3xl md:text-4xl font-serif text-gold mb-12 text-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                >
                    Team Building Experiences
                </motion.h2>
                <motion.div
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1.2, staggerChildren: 0.1 }}
                >
                    {activities.map((activity, index) => (
                        <motion.div
                            key={index}
                            className="bg-white p-6 rounded-lg shadow-lg text-center transform hover:-translate-y-2 transition-all duration-300"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <h3 className="text-lg md:text-xl text-gold mb-2">{activity.name}</h3>
                            <p className="text-dark-brown text-opacity-80 text-sm md:text-base">{activity.description}</p>
                        </motion.div>
                    ))}
                </motion.div>

                {/* Team Building Impact Section */}
                <motion.div
                    className="bg-dark-brown text-beige p-8 md:p-12 rounded-lg shadow-2xl mb-16 relative overflow-hidden"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1.4 }}
                >
                    <div className="absolute top-0 right-0 w-1/3 h-full bg-gold opacity-10 transform -skew-x-12"></div>
                    <h3 className="text-2xl md:text-3xl font-serif text-gold mb-6 relative z-10">The Impact of Team Building</h3>
                    <p className="text-lg md:text-xl leading-relaxed max-w-3xl relative z-10">
                        Our carefully crafted team building experiences go beyond fun activities. They're designed to enhance communication, boost morale, and improve productivity. Watch as your team develops a deeper understanding of each other's strengths, fostering a more collaborative and innovative work environment.
                    </p>
                </motion.div>

                {/* CTA */}
                <motion.div
                    className="text-center"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1.6 }}
                >
                    <Link
                        to="/contact"
                        className="bg-gold text-dark-brown px-8 md:px-12 py-3 md:py-4 rounded-full text-lg md:text-xl font-sans hover:bg-dark-brown hover:text-gold transition duration-300 inline-block shadow-lg"
                    >
                        Strengthen Your Team Today
                    </Link>
                </motion.div>
            </div>
        </motion.div>
    );
};

export default TeamBuilding;
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

// Import the single image
import destinationsImage from '../assets/destinations.jpg';

const destinations = [
    {
        name: "Anguilla",
        tagline: "Tranquility Wrapped in Blue",
        colors: "from-[#00247D] via-[#FFFFFF] to-[#F47821]",
        gradient: "bg-gradient-to-r from-[#00247D] 65% via-[#FFFFFF] 90% to-[#F47821]",
        textColor: "text-black"
    },
    {
        name: "Antigua and Barbuda",
        tagline: "The Beach is Just the Beginning",
        colors: "from-[#000000] via-[#CF0820] to-[#0072C6]",
        gradient: "bg-gradient-to-r from-[#000000] 40% via-[#CF0820] 70% to-[#0072C6]",
        textColor: "text-white"
    },
    {
        name: "St Kitts and Nevis",
        tagline: "Two Islands, One Paradise",
        colors: "from-[#007A3D] via-[#000000] to-[#CE1126]",
        gradient: "bg-gradient-to-r from-[#007A3D] 25% via-[#000000] 65% to-[#CE1126]",
        textColor: "text-white"
    },
    {
        name: "St Maarten",
        tagline: "The Friendly Island",
        colors: "from-[#CE1126] via-[#FFFFFF] to-[#00247D]",
        gradient: "bg-gradient-to-r from-[#CE1126] 35% via-[#FFFFFF] 70% to-[#00247D]",
        textColor: "text-black"
    },
    {
        name: "Dominica",
        tagline: "The Nature Island",
        colors: "from-[#006B3F] via-[#FFD100] to-[#000000]",
        gradient: "bg-gradient-to-r from-[#006B3F] 60% via-[#FFD100] 70% to-[#000000]",
        textColor: "text-white"
    },
    {
        name: "Grenada",
        tagline: "The Spice of the Caribbean",
        colors: "from-[#CE1126] via-[#007A5E] to-[#FCD116]",
        gradient: "bg-gradient-to-r from-[#CE1126] 50% via-[#007A5E] 75% to-[#FCD116]",
        textColor: "text-white"
    },
    {
        name: "Turks and Caicos",
        tagline: "Beautiful by Nature",
        colors: "from-[#00247D] via-[#FCD116] to-[#CE1126]",
        gradient: "bg-gradient-to-r from-[#00247D] 75% via-[#FCD116] 90% to-[#CE1126]",
        textColor: "text-white"
    },
    {
        name: "St Vincent and the Grenadines",
        tagline: "One Sea, Many Islands",
        colors: "from-[#0072C6] via-[#FFD100] to-[#009739]",
        gradient: "bg-gradient-to-r from-[#0072C6] 50% via-[#FFD100] 80% to-[#009739]",
        textColor: "text-black"
    },
    {
        name: "The Bahamas",
        tagline: "It's Better in The Bahamas",
        colors: "from-[#00ABC9] via-[#FFD100] to-[#000000]",
        gradient: "bg-gradient-to-r from-[#00ABC9] 50% via-[#FFD100] 75% to-[#000000]",
        textColor: "text-black"
    },
    {
        name: "British Virgin Islands",
        tagline: "Nature's Little Secrets",
        colors: "from-[#00247D] via-[#FFFFFF] to-[#032169]",
        gradient: "bg-gradient-to-r from-[#00247D] 50% via-[#FFFFFF] 75% to-[#032169]",
        textColor: "text-black"
    },
    {
        name: "Tobago",
        tagline: "Clean, Green, Serene",
        colors: "from-[#CE1126] via-[#FFFFFF] to-[#000000]",
        gradient: "bg-gradient-to-r from-[#CE1126] 60% via-[#FFFFFF] 70% to-[#000000]",
        textColor: "text-black"
    },
    {
        name: "Barbados",
        tagline: "Pride and Industry",
        colors: "from-[#00267F] via-[#FFC726] to-[#000000]",
        gradient: "bg-gradient-to-r from-[#00267F] 50% via-[#FFC726] 75% to-[#000000]",
        textColor: "text-white"
    },
];

const Destinations = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="min-h-screen bg-beige text-dark-brown overflow-hidden"
        >
            {/* Hero Section */}
            <div className="relative h-[75vh]">
                <img src={destinationsImage} alt="Exotic Destinations" className="w-full h-full object-cover" />
                <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-transparent flex items-center">
                    <div className="container mx-auto px-4">
                        <motion.div
                            initial={{ x: -100, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ type: "spring", stiffness: 100 }}
                            className="md:bg-transparent md:p-0 bg-black bg-opacity-50 p-6 rounded-lg inline-block"
                        >
                            <h1 className="text-4xl md:text-7xl font-serif text-gold leading-tight max-w-2xl">
                                Discover <span className="text-beige">Paradise</span>
                            </h1>
                            <p className="text-xl text-beige max-w-xl mt-4 md:hidden">
                                Explore the Caribbean's finest retreats
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="container mx-auto px-4 py-16">
                {/* Intro Text */}
                <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className="max-w-2xl mx-auto text-center mb-16"
                >
                    <p className="text-xl md:text-2xl mb-6 leading-relaxed">
                        Immerse yourself in the beauty of the Caribbean's most exclusive destinations. Our carefully curated selection of islands offers the perfect backdrop for unforgettable executive retreats.
                    </p>
                    <p className="text-lg md:text-xl mb-6 leading-relaxed">
                        From pristine beaches to lush landscapes, each location provides a unique blend of luxury, culture, and natural splendor to inspire and rejuvenate your team.
                    </p>
                </motion.div>

                {/* Featured Destinations */}
                <motion.h2
                    className="text-3xl md:text-4xl font-serif text-gold mb-12 text-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.4 }}
                >
                    Our Exclusive Destinations
                </motion.h2>
                <motion.div
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.6, staggerChildren: 0.1 }}
                >
                    {destinations.map((destination, index) => (
                        <motion.div
                            key={index}
                            className={`${destination.gradient} p-6 rounded-lg shadow-lg text-center transform hover:-translate-y-2 transition-all duration-300`}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <h3 className={`text-lg md:text-xl ${destination.textColor} mb-2 font-bold shadow-text`}>{destination.name}</h3>
                            <p className={`${destination.textColor} text-opacity-90 font-medium shadow-text text-sm md:text-base`}>{destination.tagline}</p>
                        </motion.div>
                    ))}
                </motion.div>

                {/* Destination Impact Section */}
                <motion.div
                    className="bg-dark-brown text-beige p-8 md:p-12 rounded-lg shadow-2xl mb-16 relative overflow-hidden"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.8 }}
                >
                    <div className="absolute top-0 right-0 w-1/3 h-full bg-gold opacity-10 transform -skew-x-12"></div>
                    <h3 className="text-2xl md:text-3xl font-serif text-gold mb-6 relative z-10">The Impact of Location</h3>
                    <p className="text-lg md:text-xl leading-relaxed max-w-3xl relative z-10">
                        The right setting can transform your executive retreat from ordinary to extraordinary. Our handpicked destinations provide the perfect blend of inspiration, relaxation, and adventure. Immerse your team in breathtaking landscapes and rich cultures, fostering creativity and strengthening bonds away from the distractions of everyday life.
                    </p>
                </motion.div>

                {/* CTA */}
                <motion.div
                    className="text-center"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1 }}
                >
                    <Link
                        to="/contact"
                        className="bg-gold text-dark-brown px-8 md:px-12 py-3 md:py-4 rounded-full text-lg md:text-xl font-sans hover:bg-dark-brown hover:text-gold transition duration-300 inline-block shadow-lg"
                    >
                        Plan Your Retreat Today
                    </Link>
                </motion.div>
            </div>
        </motion.div>
    );
};

export default Destinations;
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import networkingImg from '../assets/networking.jpeg';
import networking1 from '../assets/networking1.jpg';
import networking2 from '../assets/networking2.jpg';
import networking3 from '../assets/networking3.jpg';

const activities = [
    {
        name: "Executive Cocktail Parties",
        description: "Mingle with industry leaders in style"
    },
    {
        name: "Island Leadership Panels",
        description: "Gain insights from top executives"
    },
    {
        name: "Luxury Yacht Cruises",
        description: "Network while sailing crystal waters"
    },
    {
        name: "Executive Mastermind Groups",
        description: "Collaborate on high-level strategies"
    },
    {
        name: "Collaborative Innovation Labs",
        description: "Co-create solutions to industry challenges"
    },
    {
        name: "Fireside Chats",
        description: "Intimate discussions with thought leaders"
    },
    {
        name: "Roundtable Discussions",
        description: "Exchange ideas in focused groups"
    },
    {
        name: "High-Performance Leadership Panels",
        description: "Learn from elite business achievers"
    },
];

const Networking = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="min-h-screen bg-beige text-dark-brown overflow-hidden"
        >
            {/* Hero Section */}
            <div className="relative h-[75vh]">
                <img src={networkingImg} alt="Networking" className="w-full h-full object-cover" />
                <div className="absolute inset-0 md:bg-gradient-to-r md:from-black md:via-transparent md:to-transparent flex items-center">
                    <div className="container mx-auto px-4">
                        <motion.div
                            initial={{ x: -100, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ type: "spring", stiffness: 100 }}
                            className="md:bg-transparent md:p-0 bg-black bg-opacity-50 p-6 rounded-lg inline-block"
                        >
                            <h1 className="text-4xl md:text-7xl font-serif text-gold leading-tight max-w-2xl">
                                Connect and <span className="text-beige">Thrive</span>
                            </h1>
                            <p className="text-xl text-beige max-w-xl mt-4 md:hidden">
                                Forge powerful connections in paradise
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="container mx-auto px-4 py-16">
                {/* Intro Text */}
                <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className="max-w-2xl mx-auto text-center mb-16"
                >
                    <p className="text-xl md:text-2xl mb-6 leading-relaxed">
                        Expand your network in an exclusive, relaxed setting. Connect with high-level executives from various industries while enjoying the luxury of island life.
                    </p>
                    <p className="text-lg md:text-xl mb-6 leading-relaxed">
                        Our retreats offer the perfect balance of meaningful interactions and professional growth, all in a breathtaking environment. Build partnerships that can take your business to the next level.
                    </p>
                </motion.div>

                {/* Image Grid */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
                    <motion.div
                        className="col-span-1 md:col-span-2 md:row-span-2"
                        initial={{ x: -50, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.4 }}
                    >
                        <img src={networking1} alt="Networking Activity 1" className="w-full h-full object-cover rounded-lg shadow-2xl" />
                    </motion.div>
                    <motion.div
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.6 }}
                    >
                        <img src={networking2} alt="Networking Activity 2" className="w-full h-full object-cover rounded-lg shadow-2xl" />
                    </motion.div>
                    <motion.div
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.8 }}
                    >
                        <img src={networking3} alt="Networking Activity 3" className="w-full h-full object-cover rounded-lg shadow-2xl" />
                    </motion.div>
                </div>

                {/* Featured Activities */}
                <motion.h2
                    className="text-3xl md:text-4xl font-serif text-gold mb-12 text-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                >
                    Networking Experiences
                </motion.h2>
                <motion.div
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1.2, staggerChildren: 0.1 }}
                >
                    {activities.map((activity, index) => (
                        <motion.div
                            key={index}
                            className="bg-white p-6 rounded-lg shadow-lg text-center transform hover:-translate-y-2 transition-all duration-300"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <h3 className="text-lg md:text-xl text-gold mb-2">{activity.name}</h3>
                            <p className="text-dark-brown text-opacity-80 text-sm md:text-base">{activity.description}</p>
                        </motion.div>
                    ))}
                </motion.div>

                {/* Networking Impact Section */}
                <motion.div
                    className="bg-dark-brown text-beige p-8 md:p-12 rounded-lg shadow-2xl mb-16 relative overflow-hidden"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1.4 }}
                >
                    <div className="absolute top-0 right-0 w-1/3 h-full bg-gold opacity-10 transform -skew-x-12"></div>
                    <h3 className="text-2xl md:text-3xl font-serif text-gold mb-6 relative z-10">The Power of Connections</h3>
                    <p className="text-lg md:text-xl leading-relaxed max-w-3xl relative z-10">
                        In today's interconnected world, your network is your net worth. Our curated networking experiences go beyond simple introductions. They create an environment where lasting relationships are forged, innovative ideas are born, and transformative partnerships begin. Elevate your professional circle and unlock new opportunities for growth and collaboration.
                    </p>
                </motion.div>

                {/* CTA */}
                <motion.div
                    className="text-center"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1.6 }}
                >
                    <Link
                        to="/contact"
                        className="bg-gold text-dark-brown px-8 md:px-12 py-3 md:py-4 rounded-full text-lg md:text-xl font-sans hover:bg-dark-brown hover:text-gold transition duration-300 inline-block shadow-lg"
                    >
                        Expand Your Network Today
                    </Link>
                </motion.div>
            </div>
        </motion.div>
    );
};

export default Networking;